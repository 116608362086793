import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [3];

export const dictionary = {
		"/(main)": [7,[3],[4]],
		"/(main)/404": [8,[3],[4]],
		"/(429)/429": [5],
		"/(main)/admin": [9,[3],[4]],
		"/(main)/blog-post-[slug]": [11,[3],[4]],
		"/(main)/blog-tag-[slug]": [12,[3],[4]],
		"/(main)/blog": [10,[3],[4]],
		"/(main)/download-[slug]": [13,[3],[4]],
		"/(main)/faq": [14,[3],[4]],
		"/(main)/privacy": [15,[3],[4]],
		"/(main)/profile": [16,[3],[4]],
		"/(main)/search-[slug]": [17,[3],[4]],
		"/(main)/server-[slug]": [18,[3],[4]],
		"/(main)/tag-[slug]": [19,[3],[4]],
		"/(main)/terms": [20,[3],[4]],
		"/(main)/version-[slug]": [21,[3],[4]],
		"/(iframe)/widget-server-stats-[slug]": [6,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';
<script lang="ts">
    import type * as Sentry from "@sentry/sveltekit";
    import { Toast, open_modal } from "./Foreground";
    import ReportErrorModal from "./ReportErrorModal.svelte";
    import ButtonDefault from "./ButtonDefault.svelte";

    export let event: Parameters<Exclude<Parameters<typeof Sentry.init>[0]["beforeSend"], undefined>>[0];
    export let hint: Sentry.EventHint;
    export let close = () => {};

    function open() {
        open_modal(ReportErrorModal, { event, hint });
        close();
    }
</script>

<div class="toast-parent">
    <Toast content={""} type={"error"} duration={15000} {close}>
        <svelte:fragment slot="text">
            Кажется, в работе сайте произошла ошибка. Хочешь помочь нам исправить её?
            <ButtonDefault color="green" on:click={open}>Продолжить</ButtonDefault>
        </svelte:fragment>
    </Toast>
</div>

<style>
    .toast-parent {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .toast-parent :global(.toast-container) {
        max-width: 75%;
    }
    .toast-parent :global(.text) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>

import { version } from "$app/environment";
import { logger, prod } from "$lib/client_utils";
import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_SENTRY_DSN } from "$env/static/public";
import type { HandleClientError } from "@sveltejs/kit";
import { add_toast } from "$lib/components/Foreground";
import ReportErrorToast from "$lib/components/ReportErrorToast.svelte";

Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    sampleRate: 1.0,
    tracesSampleRate: 0.5,
    replaysOnErrorSampleRate: 0.2,
    replaysSessionSampleRate: 0.01,
    enabled: prod,
    release: version,
    integrations: [],
    beforeSend: async (event, hint) => {
        logger.info("sentry client event", { event, hint });
        if (event.exception) {
            // !!! sampleRate ???
            add_toast({ event, hint }, ReportErrorToast);
        }
        return event;
    },
});

const handle_error = (async ({ error, event }) => {
    if (error instanceof Error) {
        if (!error.message.startsWith("Not found:")) {
            logger.error("handleError client", event.url.href, error);
        }
    }
    return {
        message: (error as any)?.message || error,
        res: (error as any)?.message || error,
    };
}) satisfies HandleClientError;
export const handleError = Sentry.handleErrorWithSentry(handle_error);

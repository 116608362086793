<script lang="ts">
    import * as Sentry from "@sentry/sveltekit";
    import { ModalContainer, add_toast } from "./Foreground";
    import { logger, stores, stringify } from "$lib/client_utils";
    import ButtonDefault from "./ButtonDefault.svelte";

    export let event: Parameters<Exclude<Parameters<typeof Sentry.init>[0]["beforeSend"], undefined>>[0];
    export let hint: Sentry.EventHint;
    export let close = () => {};

    const error = stringify(hint.originalException);
    const { session } = stores;
    let name = $session?.name || "";
    let email = $session?.email || "";
    let text = "";

    function send() {
        const event_id = event.event_id || Sentry.lastEventId() || Sentry.captureMessage("no event_id");
        Sentry.captureUserFeedback({
            event_id,
            name: name || $session?.name || "",
            email: email || $session?.email,
            comments: text,
        });
        close();
        add_toast({ type: "success", duration: 5000, content: "Спасибо за помощь!" });
    }

    $: logger.info("event", event);
    $: logger.info("hint", hint);
</script>

<ModalContainer title="Сообщение об ошибке" {close}>
    <div class="modal-content">
        Ошибка:
        <div class="error">
            {error}
        </div>
        <div class="row">
            Имя
            <input class="input" type="text" placeholder="Имя" bind:value={name} />
            Email
            <input class="input" type="text" placeholder="Email" bind:value={email} />
        </div>
        Что случилось?
        <textarea class="textarea" rows="4" placeholder={`Я открыл страницу "...", нажал "...", затем ...`} bind:value={text} />
        <ButtonDefault color="green" on:click={send}>Отправить</ButtonDefault>
    </div>
</ModalContainer>

<style>
    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1rem;
        gap: 4px;
    }
    .row {
    }
    .input {
    }
    .textarea {
    }
    .error {
        padding: 1rem;
        border-radius: 10px;
        background-color: var(--red);
        color: var(--white);
    }
</style>
